<template>
    <b-card>

            <b-card
                    title="日志详情"
                    no-body
            >
                <b-card-header class="p-1">
                    <b-card-title class="font-medium-2">
                        <span class="align-middle ml-50">日志详情</span>
                    </b-card-title>
                </b-card-header>
                <b-table-simple
                        caption-top
                        responsive
                        :bordered="true"
                        class="rounded-bottom mb-0"
                >
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th>请求参数</b-th>
                            <b-th>访问路径</b-th>
                            <b-th>错误信息</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td width="15%">
                                {{ this.errorinfo.params}}
                            </b-td>
                            <b-td width="15%">
                                {{ this.errorinfo.url}}
                            </b-td>
                            <b-td width="70%">
                                {{ this.errorinfo.err_info }}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>

            </b-card>

            <b-row>
                <b-col
                        cols="12"
                        class="mt-50"
                >
                     <b-button
                          variant="primary"
                          class="mr-1"
                          @click="edit"
                      >
                            删除
                     </b-button>
                    <b-button
                            variant="outline-secondary"
                            @click="cancel"
                    >
                        返回
                    </b-button>
                </b-col>
            </b-row>

    </b-card>
</template>


<script>
    import {
        BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import {onUnmounted, ref} from '@vue/composition-api'
    import router from "@/router";
    import store from "@/store";
    import errorinfoStore from './errorinfoStore'
    // Notification
    import { useToast } from 'vue-toastification/composition'
    import {  getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
    import errorinfo from "../../../router/routes/apps/errorinfo";


    export default {
        components: {
            BCard,
            BMedia,
            BAvatar,
            BCardText,
            BForm,
            BLink,
            BImg,
            BRow,
            BCol,
            BButton,
            BFormGroup,
            BFormInput,
            BFormFile,
            vSelect,
            useToast,
        },
        data() {
            return {
                id: ref(0),
                errorinfo: ref({}),
            }
        },

        methods: {
            changeState: function(data) {
                const state = 1 - data.state
                store.dispatch('errorinfo/state', {id: data.id, state: state}).then(res => {
                    data.state = state
                })
            },
        },
        setup() {
            const toast = useToast()

            // Register module
            if (!store.hasModule('errorinfo')) store.registerModule('errorinfo', errorinfoStore)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule('errorinfo')) store.unregisterModule('errorinfo')
            })

            const view = function() {
                store.dispatch('errorinfo/view', {id: this.id}).then(res => {
                    this.errorinfo = res.data.data
                })
            }

            const edit = function() {
                store.dispatch('errorinfo/edit', {id:this.id}).then(res => {
                    toast.success('数据删除成功!')
                    this.$router.push({ name: 'apps-errorinfo-list'});
                })
            }

            const cancel = function() {
                this.$router.go(-1)
            }


            return {
                view,
                cancel,
                edit,

                getCodeOptions,
                getCode,
                getCodeLabel,
                getCodeColor,
            }
        },
        created() {
            this.id = this.$route.query.id || 0;
            this.view()
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>